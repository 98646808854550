import React, { useState } from 'react';

import {
  currencyFormat,
  convertStringToInteger
} from 'common/helpers/numberHelper';

import {
  addCartItem
} from 'common/helpers/cartHelper';

const NoCustomer = (props) => {
  const { product, settings } = props;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [errorMessage, setErrorMessage] = useState(null);

  const { price, sku } = product.variants[0];
  const pricingArray = product && product.loyalty && product.loyalty.pricing ? product.loyalty.pricing : [];

  const handleLogin = () => {
    const currentLink = window.location.href; 
    window.location.href = `https://www.jordanwinery.com/c7-rewards-login/?return_url=${currentLink}`;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    if (quantity === '' || Number.isNaN(quantity)) {
      setIsSubmitting(false);
      setErrorMessage('Invalid Quantity');
      return;
    }
    const qty = convertStringToInteger(quantity);
    if (qty < 1) {
      setIsSubmitting(false);
      setErrorMessage('Invalid Quantity');
      return;
    }
    const analytics = {
      viewType: 'Loyalty Widget'
    };

    const item = {
      quantity: qty,
      sku
    };
    // dispatch(addCartItem(item, analytics));
    const cart = await addCartItem(item, analytics);
    if (cart && cart.id){
      window.c7action.reloadCart();
      window.c7action.showSideCart();
      
    }else{
      if(cart && cart.message){
        setErrorMessage(cart.message);
      }
    }
    setIsSubmitting(false); 
  };

  const handleQuantity = (e) => {
    const input = e.target.value;
    const qty = input.replace(/[^0-9]/g, '');
    setQuantity(qty);
    setErrorMessage(null);
    setIsSubmitting(false);
  };



  return (
    <>
      {pricingArray.length > 0 && (
        <div className="widget-pricing-nocustomer">
          <button type="button" onClick={handleLogin} className="c7-button">
            Member Login
          </button>
        </div>
      )}
      {product && product.security && product.security.availableTo !== 'Loyalty Tier' && (
      <div className="widget-pricing-add-to-cart">
        <form onSubmit={handleSubmit}>
          {errorMessage && (
            <div className="c7-product__error" aria-describedby="qtyerror">
              Error: {errorMessage}
            </div>
          )}
          <div className="widget-pricing-add-to-cart-without-points">
            <input type="text" value={quantity} onChange={handleQuantity} />
            <span>@</span> {currencyFormat(price, settings)}
          </div>
          <button type="submit" className="c7-button" disabled={isSubmitting}>
            Add To Cart
          </button>
        </form>

        
        
      </div>
      )}
      
    </>
  );
};

export default NoCustomer;